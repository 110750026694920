import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import IconLoadingNumber from './IconLoadingNumber'

import {
  setImageLoaded,
  setImagesLoading,
  getImagesNoLoaded,
} from '../../modules/reducerLoadImages'

import { typeChangeSelector } from '../../modules/reducer'

const ImageLoader = ({ resolveImage }) => {
  const dispatch = useDispatch()

  const imagesNoLoaded = useSelector(getImagesNoLoaded)
  const imagesLoading = useSelector((state) => state.loadImages.imagesLoading)

  const typeChange = useSelector(typeChangeSelector)

  useEffect(() => {
    const checkImage = (image) =>
      new Promise((resolve) => {
        const img = new Image()
        img.onload = () => {
          resolve({ image, img })
        }
        //img.onerror = () => resolve({ path, status: 'error' })

        img.onerror = () => {
          alert('Error')
        }

        img.src = image.src
      })

    if (imagesNoLoaded.length > 0) {
      const newImagesLoading = imagesNoLoaded.reduce((result, image) => {
        if (!imagesLoading.some((im) => im.id === image.id)) {
          result.push(image)
        }
        return result
      }, [])

      dispatch(setImagesLoading(newImagesLoading))

      newImagesLoading.forEach((image) => {
        checkImage(image).then(({ image, img }) => {
          if (resolveImage) {
            resolveImage(image, img)
          }
          dispatch(setImageLoaded({ id: image.id }))
        })
      })
    }
  }, [imagesNoLoaded, imagesLoading, dispatch, resolveImage])

  //return typeChange !== 'opcion' ? <IconLoadingNumber /> : null
  return null
}

export default ImageLoader
