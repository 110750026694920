import initialState from './initialState'

import { createSelector } from 'reselect'

import { searchId, updateId } from './utils'

import { setPositionY } from './reducerDimensions'
//import { setDefaultFov } from './reducerFov'
import { setLatLon } from './reducerRotate'

//Actions

export const SET_VISIBLE = 'SET_VISIBLE'

export const SET_VISIBLE_DEFAULT = 'SET_VISIBLE_DEFAULT'
export const SET_DEFAULTS = 'SET_DEFAULTS'
export const SET_ID_ESTANCIA_ACTUAL = 'SET_ID_ESTANCIA_ACTUAL'

export const GO_BACK = 'GO_BACK'

export const SET_TYPE_CHANGE = 'SET_TYPE_CHANGE'

/*export const setVisible = (acabado, opcion) => ({
  type: SET_VISIBLE,
  acabado,
  opcion,
})

export const setVisibleDefault = (idAcabado, idOpcion) => ({
  type: SET_VISIBLE_DEFAULT,
  idAcabado,
  idOpcion,
})

export const setDefaults = () => ({
  type: SET_DEFAULTS,
})*/

const setTypeChange = (typeChange) => ({
  type: SET_TYPE_CHANGE,
  typeChange,
})

export const setVisible = (acabado, opcion) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_LOADED',
    })

    dispatch(setTypeChange('opcion'))

    dispatch({
      type: SET_VISIBLE,
      acabado,
      opcion,
    })
  }
}

export const setVisibleDefault = (idAcabado, idOpcion) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_LOADED',
    })

    dispatch(setTypeChange('opcion'))

    dispatch({
      type: SET_VISIBLE_DEFAULT,
      idAcabado,
      idOpcion,
    })
  }
}

export const setDefaults = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_LOADED',
    })

    dispatch(setTypeChange('opcion'))

    dispatch({
      type: SET_DEFAULTS,
    })
  }
}

export function setIdEstanciaActual(id) {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_LOADED',
    })

    dispatch(setTypeChange('estancia'))

    dispatch({
      type: SET_ID_ESTANCIA_ACTUAL,
      id,
    })

    dispatch(setPositionY(0))

    const state = getState()
    const { initialLat, initialLon } = searchId(state.scene.estanciasScene, id)
    dispatch(setLatLon(initialLat, initialLon))
    //dispatch(setDefaultFov())
  }
}

export const goBack = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_LOADED',
    })

    dispatch(setTypeChange('estancia'))

    dispatch({
      type: GO_BACK,
    })

    dispatch(setPositionY(0))

    const state = getState()
    const { initialLat, initialLon } = searchId(
      state.scene.estanciasScene,
      state.reducer.idEstanciaActual
    )
    dispatch(setLatLon(initialLat, initialLon))
  }
}

/*
const setEstanciasState = (state, action) => {
  const widthButtonEstancia = action.ordenEstancias.map(estancia => 0)

  const estancias = action.ordenEstancias.map(idEstancia => {
    const estancia = searchId(action.estancias, idEstancia)

    const size = estancia.size.split('_')
    const path = estancia.path === '' ? estancia.id : estancia.path
    const acabados = estancia.acabados.split(',')
    const nameButton =
      estancia.nameButton === '' ? estancia.title : estancia.nameButton
    const nameButtonIngles =
      estancia.nameButtonIngles === ''
        ? estancia.titleIngles
        : estancia.nameButtonIngles

    return {
      id: estancia.id,
      nameButton,
      nameButtonIngles,
      title: estancia.title,
      titleIngles: estancia.titleIngles,
      src: `${pathIntranet}${estancia.src}`,
      width: size[0],
      height: size[1],
      acabados,
      path,
      pdfIconosLinea: estancia.pdficonoslinea * 1
    }
  })

  const srcEstanciasLoaded = estancias.map(estancia => ({
    id: estancia.id,
    loaded: false
  }))

  return {
    estancias,
    widthButtonEstancia,
    idEstanciaActual: estancias[0].id,
    srcEstanciasLoaded
  }
}

const setAcabadosState = (state, action) => {
  const acabados = action.acabados.map(acabado => {
    const opciones = acabado.opciones.split(',')

    return {
      id: acabado.id,
      title: acabado.title,
      titleIngles: acabado.titleIngles,
      multiple: acabado.multiple === '1',
      opciones,
      parentAcabado: ''
    }
  })

  return {
    acabados
  }
}

const setOpcionesState = (state, action) => {
  const opciones = action.opciones.map(opcion => {
    const size = opcion.size.split('_')
    const srcIconoPdf =
      opcion.srcIconoPdf === ''
        ? []
        : opcion.srcIconoPdf.split(',').map(src => `${pathIntranet}${src}`)

    return {
      id: opcion.id,
      title: opcion.title,
      titleIngles: opcion.titleIngles,
      subtitle: opcion.subtitle,
      subtitleIngles: opcion.subtitleIngles,
      srcIcono: `${pathIntranet}${opcion.srcIcono}`,
      precio: opcion.precio * 1,
      src: `${pathIntranet}${opcion.src}`,
      width: size[0],
      height: size[1],
      srcIconoPdf,
      subOpciones: []
    }
  })

  return {
    opciones
  }
}
*/

const setEstanciaActual = (state, id) =>
  state.idEstanciaActual !== id
    ? {
        ...state,
        idEstanciaActual: id,
        firstCharge: true,
        antCharge: {
          idEstancia: state.idEstanciaActual,
          opcionesSeleccionadas: state.opcionesSeleccionadas,
        },
        historial: [...state.historial, state.idEstanciaActual],
      }
    : state

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VISIBLE:
      const { multiple } = searchId(state.acabados, action.acabado)

      let opcionesAcabado = []

      if (multiple) {
        opcionesAcabado = [
          ...searchId(state.opcionesSeleccionadas, action.acabado).opciones,
        ]

        const index = opcionesAcabado.indexOf(action.opcion)

        if (index === -1) {
          opcionesAcabado.push(action.opcion)
        } else {
          opcionesAcabado.splice(index, 1)
        }
      } else {
        opcionesAcabado = [action.opcion]
      }

      const opcionesSeleccionadas6 = updateId(
        state.opcionesSeleccionadas,
        action.acabado,
        'opciones',
        opcionesAcabado
      )

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas6,
        firstCharge: false,
      }

    case SET_VISIBLE_DEFAULT:
      const acabado = searchId(state.acabados, action.idAcabado)

      let opcionesSeleccionadas1 = []

      if (acabado.multiple) {
        let { opciones: opS } = searchId(
          state.opcionesSeleccionadas,
          action.idAcabado
        )

        const opS1 = []

        for (let i = 0, iLen = opS.length; i < iLen; i++) {
          if (opS[i] !== action.idOpcion) {
            opS1.push(opS[i])
          }
        }

        opcionesSeleccionadas1 = updateId(
          state.opcionesSeleccionadas,
          action.idAcabado,
          'opciones',
          opS1
        )
      } else {
        opcionesSeleccionadas1 = updateId(
          state.opcionesSeleccionadas,
          action.idAcabado,
          'opciones',
          acabado.opciones.length > 0 ? [acabado.opciones[0]] : []
        )
      }

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas1,
      }
    case SET_DEFAULTS:
      const opcionesSeleccionadas2 = state.acabados.map((acabado) => {
        return {
          id: acabado.id,
          opciones: acabado.multiple ? [] : [acabado.opciones[0]],
        }
      })

      return {
        ...state,
        opcionesSeleccionadas: opcionesSeleccionadas2,
      }
    case SET_ID_ESTANCIA_ACTUAL:
      return setEstanciaActual(state, action.id)
    case GO_BACK:
      if (state.historial.length === 0) {
        return state
      } else {
        const idEstancia = state.historial[state.historial.length - 1]
        const newState = setEstanciaActual(state, idEstancia)
        return {
          ...newState,
          historial: newState.historial.slice(0, -2),
        }
      }
    case SET_TYPE_CHANGE:
      return {
        ...state,
        typeChange: action.typeChange,
      }
    default:
      return state
  }
}

export const estanciasSelector = (state) => state.reducer.estancias
const acabadosSelector = (state) => state.reducer.acabados
const opcionesSelector = (state) => state.reducer.opciones
export const idEstanciaActualSelector = (state) =>
  state.reducer.idEstanciaActual
const opcionesSeleccionadasSelector = (state) =>
  state.reducer.opcionesSeleccionadas
const antChargeSelector = (state) => state.reducer.antCharge
const srcPathSelector = (state) => state.reducer.srcPath
export const typeChangeSelector = (state) => state.reducer.typeChange

export const getIdEstancias = createSelector([estanciasSelector], (estancias) =>
  estancias.map((estancia) => estancia.id)
)

export const getSubopcionesValidas = (
  idAcabado,
  idParentAcabado,
  opciones,
  opcionesSeleccionadas
) => {
  const { opciones: idOpcionesSeleccionadasParent } = searchId(
    opcionesSeleccionadas,
    idParentAcabado
  )

  const subopcionesValidas = idOpcionesSeleccionadasParent.reduce(
    (result, idOpcionParent) => {
      const { subOpciones } = searchId(opciones, idOpcionParent)

      return result.concat(subOpciones)
    },
    []
  )

  const subopcionesSeleccionadas = searchId(
    opcionesSeleccionadas,
    idAcabado
  ).opciones.reduce((result, op) => {
    if (subopcionesValidas.indexOf(op) !== -1) {
      result.push(op)
    }

    return result
  }, [])

  return {
    subopcionesValidas,
    subopcionesSeleccionadas,
  }
}

export const getAcabado = () =>
  createSelector(
    [
      acabadosSelector,
      opcionesSeleccionadasSelector,
      opcionesSelector,
      (_, idAcabado) => idAcabado,
    ],
    (acabados, opcionesSeleccionadas, opciones, idAcabado) => {
      const acabado = searchId(acabados, idAcabado)

      let opciones1 = []
      let opcionesSeleccionadas1 = []
      if (acabado.parentAcabado !== '') {
        const {
          subopcionesValidas,
          subopcionesSeleccionadas,
        } = getSubopcionesValidas(
          acabado.id,
          acabado.parentAcabado,
          opciones,
          opcionesSeleccionadas
        )

        opciones1 = subopcionesValidas
        opcionesSeleccionadas1 = subopcionesSeleccionadas
      } else {
        opciones1 = acabado.opciones
        opcionesSeleccionadas1 = searchId(opcionesSeleccionadas, idAcabado)
          .opciones
      }

      return {
        ...acabado,
        id: acabado.id,
        title: acabado.title,
        opciones: opciones1,
        opcionesSeleccionadas: opcionesSeleccionadas1,
      }
    }
  )

export const getOpcionMenu = () =>
  createSelector(
    [opcionesSelector, (_, idOpcion) => idOpcion],
    (opciones, idOpcion) => {
      const opcion = searchId(opciones, idOpcion)

      return {
        title: opcion.title,
        subtitle: opcion.subtitle,
        srcIcono: opcion.srcIcono,
      }
    }
  )

export const getAcabados = createSelector(
  [estanciasSelector, idEstanciaActualSelector],
  (estancias, idEstanciaActual) => {
    const { acabados: idAcabados } = searchId(estancias, idEstanciaActual)

    return idAcabados
  }
)

const getImagesVisibleParametros = ({
  estancias,
  acabados,
  opciones,
  idEstancia,
  opcionesSeleccionadas,
}) => {
  const result = []

  const estanciaActual = searchId(estancias, idEstancia)

  result.push({
    id: estanciaActual.id,
    src: estanciaActual.src,
    width: estanciaActual.width,
    height: estanciaActual.height,
    visible: true,
    tipo: 'estancia',
  })

  estanciaActual.acabados.forEach((idAcabado) => {
    const { opciones: idOpciones, parentAcabado } = searchId(
      acabados,
      idAcabado
    )

    const opcionesSeleccionadas1 =
      parentAcabado !== ''
        ? getSubopcionesValidas(
            idAcabado,
            parentAcabado,
            opciones,
            opcionesSeleccionadas
          ).subopcionesSeleccionadas
        : searchId(opcionesSeleccionadas, idAcabado).opciones

    idOpciones.forEach((idOpcion) => {
      const opcion = searchId(opciones, idOpcion)

      if (opcion.src !== '') {
        result.push({
          id: opcion.id,
          src: opcion.src,
          width: opcion.width,
          height: opcion.height,
          visible: opcionesSeleccionadas1.indexOf(opcion.id) !== -1,
          tipo: 'opcion',
        })
      }
    })
  })

  return result
}

export const getImagesVisible = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idEstanciaActualSelector,
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idEstanciaActual) => {
    return getImagesVisibleParametros({
      estancias,
      acabados,
      opciones,
      idEstancia: idEstanciaActual,
      opcionesSeleccionadas,
    })
  }
)

export const getImagesVisibleAnt = createSelector(
  [estanciasSelector, acabadosSelector, opcionesSelector, antChargeSelector],
  (estancias, acabados, opciones, antCharge) => {
    return getImagesVisibleParametros({
      estancias,
      acabados,
      opciones,
      idEstancia: antCharge.idEstancia,
      opcionesSeleccionadas: antCharge.opcionesSeleccionadas,
    })
  }
)

export const getImagesLoader = createSelector(
  [
    estanciasSelector,
    opcionesSeleccionadasSelector,
    srcPathSelector,
    idEstanciaActualSelector,
  ],
  (estancias, opcionesSeleccionadas, srcPath, idEstanciaActual) => {
    let result1 = []

    const estancia = searchId(estancias, idEstanciaActual)

    if (estancia.acabados.length === 0) {
      result1 = result1.concat([
        {
          id: estancia.id,
          src: estancia.id,
        },
      ])
    } else {
      const id = estancia.acabados.reduce((result, idAcabado) => {
        const opcion = searchId(opcionesSeleccionadas, idAcabado)
        return `${result}_${idAcabado}_${opcion.opciones.join('_')}`
      }, estancia.id)

      result1 = result1.concat([
        {
          id,
          src: id,
        },
      ])
    }

    return result1.map((image) => ({
      id: image.id,
      src: `${srcPath}${image.src}.jpg`,
    }))
  }
)

export const getImagesLoader3 = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    srcPathSelector,
    idEstanciaActualSelector,
  ],
  (
    estancias,
    acabados,
    opciones,
    opcionesSeleccionadas,
    srcPath,
    idEstanciaActual
  ) => {
    let result1 = []

    const estancia = searchId(estancias, idEstanciaActual)

    //estancias.forEach((estancia) => {
    if (estancia.acabados.length === 0) {
      result1 = result1.concat([
        {
          id: estancia.id,
          src: estancia.id,
        },
      ])
    }

    let result = []

    estancia.acabados.forEach((idAcabado) => {
      const acabado = searchId(acabados, idAcabado)

      if (result.length === 0) {
        result = acabado.opciones.map((idOpcion) => ({
          id: `${estancia.id}_${idAcabado}_${idOpcion}`,
          src: `${estancia.id}_${idAcabado}_${idOpcion}`,
        }))
      } else {
        result = result.reduce(
          (result, image) =>
            result.concat(
              acabado.opciones.map((idOpcion) => ({
                id: `${image.id}_${idAcabado}_${idOpcion}`,
                src: `${image.src}_${idAcabado}_${idOpcion}`,
              }))
            ),
          []
        )
      }
    })

    result1 = result1.concat(result)
    //  })

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )

    return result1.map((image) => ({
      id: image.id,
      src: `${srcPath}${isMobile ? 'sd/' : ''}${image.src}.jpg`,
    }))
  }
)

export const getImagesLoader1 = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    srcPathSelector,
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, srcPath) => {
    let result1 = []

    estancias.forEach((estancia) => {
      if (estancia.acabados.length === 0) {
        result1 = result1.concat([
          {
            id: estancia.id,
            src: estancia.id,
          },
        ])
      }

      let result = []

      estancia.acabados.forEach((idAcabado) => {
        const acabado = searchId(acabados, idAcabado)

        if (result.length === 0) {
          result = acabado.opciones.map((idOpcion) => ({
            id: `${estancia.id}_${idAcabado}_${idOpcion}`,
            src: `${estancia.id}_${idAcabado}_${idOpcion}`,
          }))
        } else {
          result = result.reduce(
            (result, image) =>
              result.concat(
                acabado.opciones.map((idOpcion) => ({
                  id: `${image.id}_${idAcabado}_${idOpcion}`,
                  src: `${image.src}_${idAcabado}_${idOpcion}`,
                }))
              ),
            []
          )
        }
      })

      result1 = result1.concat(result)
    })

    return result1.map((image) => ({
      id: image.id,
      src: `${srcPath}${image.src}.jpg`,
    }))
  }
)

const getImageVisibleParametros = ({
  estancias,
  acabados,
  opciones,
  opcionesSeleccionadas,
  idEstancia,
}) => {
  const estancia = searchId(estancias, idEstancia)

  return {
    id:
      estancia.acabados.length === 0
        ? idEstancia
        : estancia.acabados.reduce((result, idAcabado) => {
            const acabado = searchId(acabados, idAcabado)
            const opcionesSeleccionadas1 = searchId(
              opcionesSeleccionadas,
              idAcabado
            ).opciones

            const idOpcionVisible = acabado.opciones.reduce(
              (result1, idOpcion) =>
                result1 === ''
                  ? opcionesSeleccionadas1.indexOf(idOpcion) !== -1
                    ? idOpcion
                    : ''
                  : result1,
              ''
            )

            return `${result}_${idAcabado}_${idOpcionVisible}`
          }, idEstancia),
  }
}

export const getImageVisible = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idEstanciaActualSelector,
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idEstanciaActual) =>
    getImageVisibleParametros({
      estancias,
      acabados,
      opciones,
      opcionesSeleccionadas,
      idEstancia: idEstanciaActual,
    })
)

export const getImageVisibleAnt = createSelector(
  [estanciasSelector, acabadosSelector, opcionesSelector, antChargeSelector],
  (estancias, acabados, opciones, antCharge) =>
    getImageVisibleParametros({
      estancias,
      acabados,
      opciones,
      idEstancia: antCharge.idEstancia,
      opcionesSeleccionadas: antCharge.opcionesSeleccionadas,
    })
)

/*export const getImageVisible1 = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
    idEstanciaActualSelector
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas, idEstanciaActual) => {
    const estanciaActual = searchId(estancias, idEstanciaActual)

    if (estanciaActual.acabados.length === 0) {
      return { id: idEstanciaActual }
    }

    return estanciaActual.acabados.reduce((result, idAcabado) => {
      const acabado = searchId(acabados, idAcabado)

      const idOpcionVisible = acabado.opciones.reduce(
        (result1, idOpcion) =>
          opcionesSeleccionadas.indexOf(idOpcion) !== -1 ? idOpcion : '',
        ''
      )

      return `${result}_${idAcabado}_${idOpcionVisible}`
    }, idEstanciaActual)
  }
)
*/
export const getOpcionesSeleccionadas = createSelector(
  [
    estanciasSelector,
    acabadosSelector,
    opcionesSelector,
    opcionesSeleccionadasSelector,
  ],
  (estancias, acabados, opciones, opcionesSeleccionadas) => {
    const result = []

    estancias.forEach((estancia) => {
      estancia.acabados.forEach((idAcabado) => {
        const {
          title,
          multiple,
          opciones: opcionesAcabado,
          parentAcabado,
        } = searchId(acabados, idAcabado)

        const idOpciones =
          parentAcabado !== ''
            ? getSubopcionesValidas(
                idAcabado,
                parentAcabado,
                opciones,
                opcionesSeleccionadas
              ).subopcionesSeleccionadas
            : searchId(opcionesSeleccionadas, idAcabado).opciones

        idOpciones.forEach((idOpcion) => {
          const defaultOp = multiple
            ? false
            : opcionesAcabado.length === 0
            ? true
            : opcionesAcabado[0] === idOpcion

          const opcion = searchId(opciones, idOpcion)

          const index = result.length

          result.push({
            id: opcion.id,
            idEstancia: estancia.id,
            srcBase: estancia.src,
            idAcabado,
            index,
            acabadoTitle: title,
            title: opcion.title,
            subtitle: opcion.subtitle,
            srcIcono: opcion.srcIcono,
            srcOpcion: opcion.src,
            default: defaultOp,
          })
        })
      })
    })

    return result
  }
)

export const getEstanciaActualTitle = createSelector(
  [estanciasSelector, idEstanciaActualSelector],
  (estancias, idEstanciaActual) => {
    const estancia = searchId(estancias, idEstanciaActual)
    return estancia.title
  }
)

export const getEstanciaActualType = createSelector(
  [estanciasSelector, idEstanciaActualSelector],
  (estancias, idEstanciaActual) => {
    return searchId(estancias, idEstanciaActual).type
  }
)
