import React from 'react'

import Measure from 'react-measure'

const MeasureHeight = ({ setSize }) => {
  return (
    <Measure
      bounds
      onResize={contentRect => {
        setSize(contentRect.bounds.height)
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} style={{ width: 0, height: '100%' }} />
      )}
    </Measure>
  )
}

export default MeasureHeight
