import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { setHeightMenu } from '../../modules/reducerDimensions'

import Measure from 'react-measure'

const MenuScroll = ({ endMenu, showSlider, children }) => {
  const dispatch = useDispatch()

  const dsetHeightMenu = useCallback(
    contentRect => {
      dispatch(setHeightMenu(contentRect.bounds.height))
    },
    [dispatch]
  )

  const classWidth = showSlider ? 'col-11_sm-12' : 'col-12_sm-12'

  return (
    <Measure bounds onResize={dsetHeightMenu}>
      {({ measureRef }) => (
        <div className={classWidth} ref={measureRef}>
          {children}
        </div>
      )}
    </Measure>
  )
}

export default MenuScroll
