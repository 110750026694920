import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEstanciaActualHotspots,
  getTexturesVisible,
  getAntTexturesVisible,
  setPositionHotspot,
} from '../../modules/reducerScene'
import { getFov } from '../../modules/reducerFov'

import { Canvas } from 'react-three-fiber'

import SkyBox from './SkyBox'
//import Controls from './Controls'
import CalculatePositions from './CalculatePositions'
import ChangeFov from './ChangeFov'

const Scene = () => {
  const dispatch = useDispatch()
  const texturesVisible = useSelector(getTexturesVisible)
  const antTexturesVisible = useSelector(getAntTexturesVisible)
  const hotspots = useSelector(getEstanciaActualHotspots)
  const firstCharge = useSelector((state) => state.reducer.firstCharge)
  //const autoRotate = useSelector(state => state.scene.autoRotate)
  const fov = useSelector(getFov)
  const lon = useSelector((state) => state.rotate.lon)
  const lat = useSelector((state) => state.rotate.lat)

  const setPositions = useCallback(
    (positions) => dispatch(setPositionHotspot(positions)),
    [dispatch]
  )
  console.log(texturesVisible)
  console.log(antTexturesVisible)
  return (
    <Canvas
      gl={{ preserveDrawingBuffer: true, antialias: true }}
      camera={{
        position: [0, 0, 0.001],
        near: 0.5,
        far: 10000,
      }}
    >
      <CalculatePositions hotspots={hotspots} setPositions={setPositions} />
      <ChangeFov fov={fov} lon={lon} lat={lat} />
      <SkyBox
        textures={texturesVisible}
        firstCharge={firstCharge}
        firstTextures={antTexturesVisible}
      />
    </Canvas>
  )
}
//<Controls autoRotate={false} />
export default React.memo(Scene)
