import textos from './initialStateTextos'

export const initialState = {
  textos,
  estancias: [
    {
      id: 'e5',
      title: 'Baño 1',
      acabados: ['a12'],
      src:
        'images/360_mesas/LasMesa_Bano1_Opcion1_KerabenMixitBeige_KerabenMixitConceptBeige_KerabenEvolutionBeige.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'e6',
      title: 'Baño 2',
      acabados: ['a13'],
      src:
        'images/360_mesas/LasMesa_Bano2_Opcion1_KerabenMixitBeige_KerabenEvolutionBeige.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'e7',
      title: 'Cocina',
      acabados: ['a14', 'a15', 'a16'],
      src:
        'images/360_mesas/LasMesas_Cocina_Opcion1_KerabenEvolutionBeige_PinturaBlancaRAL9003_BlancoUltragloss_SilestoneBlandoNorte.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'e8',
      title: 'Dormitorio',
      acabados: [],
      src: 'images/360_mesas/LasMesas_Dormitorio.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'e9',
      title: 'Salón',
      acabados: ['a17', 'a18', 'a19'],
      src:
        'images/360_mesas/LasMesas_Salon_Opcion1_KerabenEvolutionBeige_PinturaBlancaRAL9003_BlancoUltragloss_SilestoneBlandoNorte.jpg',
      width: 1500,
      height: 1500,
      pdfIconosLinea: 2
    },
    {
      id: 'e10',
      title: 'Terraza',
      acabados: [],
      src: 'images/360_mesas/LasMesas_Terraza.jpg',
      width: 1500,
      height: 1500,
      pdfIconosLinea: 2
    }
  ],
  acabados: [
    {
      id: 'a12',
      title: 'Solado / Alicatado',
      opciones: ['op50', 'op51'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a13',
      title: 'Solado / Alicatado',
      opciones: ['op52', 'op53'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a14',
      title: 'Muebles y Encimera',
      opciones: ['op54', 'op55', 'op56'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a15',
      title: 'Pintura',
      opciones: ['op57', 'op58', 'op59'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a16',
      title: 'Solado',
      opciones: ['op60', 'op61'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a17',
      title: 'Muebles y Encimera',
      opciones: ['op62', 'op63', 'op64'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a18',
      title: 'Pintura',
      opciones: ['op65', 'op66', 'op67'],
      multiple: false,
      parentAcabado: ''
    },
    {
      id: 'a19',
      title: 'Solado',
      opciones: ['op68', 'op69'],
      multiple: false,
      parentAcabado: ''
    }
  ],
  opciones: [
    {
      id: 'op50',
      title: 'Opción 1',
      subtitle: [
        'Keraben Mixit Beige',
        'Keraben Mixit Concept Beige',
        'Keraben Evolution Beige'
      ],
      srcIcono:
        'images/360_mesas/icons/LasMesa_Bano1_Opcion1_KerabenMixitBeige_KerabenMixitConceptBeige_KerabenEvolutionBeige.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op51',
      title: 'Opción 2',
      subtitle: [
        'Keraben Nature Grey',
        'Keraben Nature Concept Grey',
        'Newker Qstone Ivory'
      ],
      srcIcono:
        'images/360_mesas/icons/LasMesa_Bano1_Opcion2_KerabenNatureGrey_KerabenNatureConceptGrey_NewkerQstoneIvory.jpg',

      src:
        'images/360_mesas/LasMesa_Bano1_Opcion2_KerabenNatureGrey_KerabenNatureConceptGrey_NewkerQstoneIvory.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'op52',
      title: 'Opción 1',
      subtitle: ['Keraben Mixit Beige', 'Keraben Evolution Beige'],
      srcIcono:
        'images/360_mesas/icons/LasMesa_Bano2_Opcion1_KerabenMixitBeige_KerabenEvolutionBeige.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op53',
      title: 'Opción 2',
      subtitle: ['Keraben Brancato Blanco', 'Newker Qstone Ivory'],
      srcIcono:
        'images/360_mesas/icons/LasMesa_Bano2_Opcion2_KerabenBrancatoBlanco_NewkerQstoneIvory.jpg',

      src:
        'images/360_mesas/LasMesa_Bano2_Opcion2_KerabenBrancatoBlanco_NewkerQstoneIvory.jpg',
      width: 1500,
      height: 1500
    },
    {
      id: 'op54',
      title: 'Opción 1',
      subtitle: ['Blanco Ultragloss', 'Silestone Blanco Norte'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_BlancoUltragloss_SilestoneBlancoNorte.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op55',
      title: 'Opción 2',
      subtitle: ['Blanco Ultramatt', 'Silestone Marengo'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_BlancoUltramatt_SilestoneMarengo.jpg',

      src:
        'images/360_mesas/LasMesas_Cocina_Opcion2_BlancoUltramatt_SilestoneMarengo.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op56',
      title: 'Opción 3',
      subtitle: ['City Borneo Marron', 'Silestone White Storm'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_opcion3_CityBorneoMarron_SilestoneWhiteStorm.jpg',

      src:
        'images/360_mesas/LasMesas_Cocina_Opcion3_CityBorneoMarron_SilestoneWhiteStorm.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op57',
      title: 'Opción 1',
      subtitle: ['Pintura Blanca RAL9003'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_PinturaBlancaRAL9003.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op58',
      title: 'Opción 2',
      subtitle: ['Pintura Beige RAL9001'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_PinturaBeigeRAL9001.jpg',

      src: 'images/360_mesas/LasMesas_Cocina_Opcion2_PinturaBeigeRAL9001.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op59',
      title: 'Opción 3',
      subtitle: ['Pintura gris NCS S 1000N'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion3_Pintura_gris_NCS_S_1000N.jpg',

      src:
        'images/360_mesas/LasMesas_Cocina_Opcion3_Pintura_gris_NCS_S_1000N.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op60',
      title: 'Opción 1',
      subtitle: ['Keraben Evolution Beige'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_KerabenEvolutionBeige.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op61',
      title: 'Opción 2',
      subtitle: ['Newker Qstone Ivory'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_NewkerQstoneIvory.jpg',

      src: 'images/360_mesas/LasMesas_Cocina_Opcion2_NewkerQstoneIvory.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op62',
      title: 'Opción 1',
      subtitle: ['Blanco Ultragloss', 'Silestone Blanco Norte'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_BlancoUltragloss_SilestoneBlancoNorte.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op63',
      title: 'Opción 2',
      subtitle: ['Blanco Ultramatt', 'Silestone Marengo'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_BlancoUltramatt_SilestoneMarengo.jpg',

      src:
        'images/360_mesas/LasMesas_Salon_Opcion2_BlancoUltramatt_SilestoneMarengo.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op64',
      title: 'Opción 3',
      subtitle: ['City Borneo Marron', 'Silestone White Storm'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_opcion3_CityBorneoMarron_SilestoneWhiteStorm.jpg',

      src:
        'images/360_mesas/LasMesas_Salon_Opcion3_CityBorneoMarron_SilestoneWhiteStorm.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op65',
      title: 'Opción 1',
      subtitle: ['Pintura Blanca RAL9003'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_PinturaBlancaRAL9003.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op66',
      title: 'Opción 2',
      subtitle: ['Pintura Beige RAL9001'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_PinturaBeigeRAL9001.jpg',

      src: 'images/360_mesas/LasMesas_Salon_Opcion2_PinturaBeigeRAL9001.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op67',
      title: 'Opción 3',
      subtitle: ['Pintura gris NCS S 1000N'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion3_Pintura_gris_NCS_S_1000N.jpg',

      src:
        'images/360_mesas/LasMesas_Salon_Opcion3_Pintura_gris_NCS_S_1000N.png',
      width: 1500,
      height: 1500
    },
    {
      id: 'op68',
      title: 'Opción 1',
      subtitle: ['Keraben Evolution Beige'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion1_KerabenEvolutionBeige.jpg',

      src: '',
      width: 1500,
      height: 1500
    },
    {
      id: 'op69',
      title: 'Opción 2',
      subtitle: ['Newker Qstone Ivory'],
      srcIcono:
        'images/360_mesas/icons/LasMesas_Salon-Cocina_Opcion2_NewkerQstoneIvory.jpg',

      src: 'images/360_mesas/LasMesas_Salon_Opcion2_NewkerQstoneIvory.png',
      width: 1500,
      height: 1500
    }
  ],
  idEstanciaActual: 'e9',
  opcionesSeleccionadas: [
    { id: 'a12', opciones: ['op50'] },
    { id: 'a13', opciones: ['op52'] },
    { id: 'a14', opciones: ['op54'] },
    { id: 'a15', opciones: ['op57'] },
    { id: 'a16', opciones: ['op60'] },
    { id: 'a17', opciones: ['op62'] },
    { id: 'a18', opciones: ['op65'] },
    { id: 'a19', opciones: ['op68'] }
  ],

  antCharge: {
    idEstancia: 'e9',
    opcionesSeleccionadas: [
      { id: 'a12', opciones: ['op50'] },
      { id: 'a13', opciones: ['op52'] },
      { id: 'a14', opciones: ['op54'] },
      { id: 'a15', opciones: ['op57'] },
      { id: 'a16', opciones: ['op60'] },
      { id: 'a17', opciones: ['op62'] },
      { id: 'a18', opciones: ['op65'] },
      { id: 'a19', opciones: ['op68'] }
    ]
  },

  firstCharge: true,
  historial: [],

  srcPath: 'images/360_mesas_jpg/'
}

export default initialState
