import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import ReactTooltip from 'react-tooltip'

const useStyles = createUseStyles({
  hotspot: {
    width: 40,
    height: 40,
    userSelect: 'none'
  },
  hotspotContainer: {
    position: 'absolute',
    left: props => props.left,
    top: props => props.top,
    cursor: 'pointer'
  }
})

export const HotspotPoint = ({ points, tooltip, onClick }) => {
  const theme = useTheme()
  const classes = useStyles({
    left: points[0] !== null ? points[0].left : -1000,
    top: points[0] !== null ? points[0].top : 1000,
    theme
  })

  return points[0] === null ? null : (
    <>
      <ReactTooltip type="light" />
      <div data-tip={tooltip} className={classes.hotspotContainer}>
        <img
          className={classes.hotspot}
          src="images/image_3.png"
          onClick={onClick}
          onTouchEnd={onClick}
          alt=""
        />
      </div>
    </>
  )
}

export default HotspotPoint
