import React, { useState, useMemo, useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getOpcionMenu, setVisible } from '../../modules/reducer'

import MeasureWidth from './MeasureWidth'

const useStyles = createUseStyles({
  wrapperFrame: {
    width: '50%',
    cursor: 'pointer',
    display: 'flex',
    flex: 'none',
    flexDirection: 'column', //'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    '-webkit-tap-highlight-color': 'transparent'
    /*  backgroundColor: ({ visible, theme }) =>
      visible ? theme.backgroundFrame : 'transparent'*/
  },
  wrapperIcono: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxSizing: 'border-box'
  },
  icono: {
    //borderRadius: '50%',
    height: 60, //70,
    width: 100 //70
  },
  title: {
    fontWeight: 500,
    fontSize: '0.65em',
    whiteSpace: 'normal',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: '0.41em',
    fontWeight: 300,
    whiteSpace: 'normal',
    textAlign: 'center'
  },
  precio: { paddingLeft: '1.5em' },
  wrapperTexto: {
    //width: props => props.width - 90,
    width: 100,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    height: 47,
    color: ({ visible, theme }) => (visible ? '#ffffff' : '#434343'),
    backgroundColor: ({ visible, theme }) =>
      visible ? 'rgb(184,42,41)' : 'rgb(211,211,211)'
  }
})
//190
//70

//127

const Frame = ({ id, idAcabado, visible, padding }) => {
  const dispatch = useDispatch()

  const getO = useMemo(getOpcionMenu, [])
  const opcion = useSelector(state => getO(state, id))

  const srcIcono = opcion.srcIcono
  const title = opcion.title
  const subtitle = opcion.subtitle

  const [width, setWidth] = useState(0)

  const moving = useSelector(state => state.dimensions.movingY)

  const onClick = useCallback(() => {
    if (!moving) {
      dispatch(setVisible(idAcabado, id))
    }
  }, [dispatch, id, idAcabado, moving])

  const theme = useTheme()
  const classes = useStyles({
    visible,
    width,
    theme
  })

  return (
    <div
      className={classes.wrapperFrame}
      style={{ padding: '5px 0 5px 0' }}
      onClick={onClick}
    >
      <MeasureWidth setSize={setWidth} />
      <div className={classes.wrapperIcono} style={{ marginLeft: padding }}>
        <img className={classes.icono} alt="" src={srcIcono} />
      </div>
      <div className={classes.wrapperTexto}>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>
            {subtitle.map((element, index) => (
              <div key={index}>{element}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Frame
