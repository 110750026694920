import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAcabado } from '../../modules/reducer'

import Tree from './Tree'
import FrameRow from './FrameRow'

const Acabado = ({ id, padding }) => {
  const getA = useMemo(getAcabado, [])

  const acabado = useSelector(state => getA(state, id))
  const title = acabado.title
  const opciones = acabado.opciones
  const opcionesSeleccionadas = acabado.opcionesSeleccionadas

  const opcionesRow = opciones.reduce((result, opcion, index) => {
    if (index % 2 === 0) {
      return [...result, [opcion, null]]
    } else {
      result[result.length - 1][1] = opcion
      return result
    }
  }, [])

  return (
    <Tree name={title} padding={padding} open={true}>
      {opcionesRow.map((opcionRow, index) => (
        <FrameRow
          key={index}
          idOpcion1={opcionRow[0]}
          idOpcion2={opcionRow[1]}
          idAcabado={id}
          padding={padding}
          visible1={opcionesSeleccionadas.indexOf(opcionRow[0]) !== -1}
          visible2={opcionesSeleccionadas.indexOf(opcionRow[1]) !== -1}
        />
      ))}
    </Tree>
  )

  /*return (
    <Tree name={title} padding={padding} open={true}>
      {opciones.map(idOpcion => (
        <Frame
          key={idOpcion}
          id={idOpcion}
          idAcabado={id}
          padding={padding}
          visible={opcionesSeleccionadas.indexOf(idOpcion) !== -1}
        />
      ))}
    </Tree>
  )*/
}

export default Acabado
