const textos = {
  informacion: [
    {
      id: 'es',
      texto:
        'La información, infografías y documentación gráfica que aparecen es este folleto son orientativas, pueden incluir acabados extras no incluidos y están sujetas a modificaciones derivadas del proyecto definitivo y de su construcción. Todo el mobiliario es meramente decorativo. La restante información contemplada en el R.D. 218/2005 de la Junta de Andalucía se encuentra en nuestras oficinas para su consulta.'
    },
    {
      id: 'en',
      texto:
        'Information, computer graphics and graphic documentation that appear in this brochure are indicative, finishes may include extras not included and are subject to changes arising from final project and its construction. All the furniture is merely decorative. The other information referred to in R.D.218/2005 of the Government of Andalusia, is available in our offices for consultation.'
    }
  ],
  cerrar: [{ id: 'es', texto: 'Cerrar' }, { id: 'en', texto: 'Close' }],
  iva: [
    { id: 'es', texto: '(IVA no incluido)' },
    { id: 'en', texto: '(VAT not included)' }
  ],
  botonResumen: [
    { id: 'es', texto: 'Ver resumen' },
    { id: 'en', texto: 'View summary' }
  ],
  botonReset: [
    { id: 'es', texto: '¿Borrar selección?' },
    { id: 'en', texto: 'Reset selection?' }
  ],
  generarPDF: [
    { id: 'es', texto: 'Generar PDF' },
    { id: 'en', texto: 'Generate PDF' }
  ],
  guardarConfiguraciones: [
    { id: 'es', texto: 'Guardar configuraciones' },
    { id: 'en', texto: 'Save configurations' }
  ],
  legal: [
    {
      id: 'es',
      texto:
        'Como Responsable del Tratamiento, Lar Inmobiliaria, le informa de que los datos contenidos en este formulario serán utilizados con la finalidad de gestionar el envío de información y prospección comercial en base al consentimiento expresamente otorgado. Sus datos serán cedidos a compañías del grupo, proveedores con las garantías necesarias y organismos públicos por obligación legal. Podrá revocar este consentimiento y ejercer el resto de los derechos relativos a sus datos de acuerdo con nuestra política de privacidad.'
    },
    {
      id: 'en',
      texto:
        'As a Processing Manager, Lar Inmobiliaria informs you that the data contained in this form will be used for the purpose of managing the sending of information and commercial research, based on the expressly granted consent. Your data will be transferred to companies in the group, providers with the necessary guarantees and public bodies due to legal obligation. You may revoke this consent and exercise the other rights relating to your data in accordance with our privacy policy.'
    }
  ],
  headerPdf: [
    { id: 'es', texto: 'TU VIVIENDA PERSONALIZADA' },
    { id: 'en', texto: 'YOUR CUSTOMIZED HOUSING' }
  ]
}

export default textos
