import React from 'react'

import { createUseStyles } from 'react-jss'

import { useSelector } from 'react-redux'
import { getAcabados, getEstanciaActualTitle } from '../../modules/reducer'

import { useSpring, animated } from 'react-spring'

import Acabado from './Acabado'
import MenuViewport from './MenuViewport'

//import Plano from '../Plano/Plano'
//import Botones from './Botones'

const useStyles = createUseStyles({
  menuEstancias: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: 230, //'18%', //'16.666667%',
    top: 0,
    //  left: 0,
    backgroundColor: 'white',
    opacity: 0.9,
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column'
  }
})

const MenuEstancias = () => {
  const estanciaTitle = useSelector(getEstanciaActualTitle)
  const acabados = useSelector(getAcabados)

  const { left } = useSpring({ left: acabados.length === 0 ? -230 : 0 })

  const padding = 20

  const classes = useStyles()

  return (
    <animated.div className={classes.menuEstancias} style={{ left }}>
      <div style={{ height: 60, width: '100%' }} />
      <div
        style={{
          padding: 0,
          height: 49,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(191,191,191)',
          fontSize: 20,
          fontWeight: 'normal',
          textTransform: 'uppercase',
          marginTop: 10,
          marginBottom: 5
        }}
      >
        {estanciaTitle}
      </div>
      <MenuViewport>
        {acabados.map(idAcabado => (
          <Acabado key={idAcabado} id={idAcabado} padding={padding} />
        ))}
      </MenuViewport>
    </animated.div>
  )
}

export default MenuEstancias
