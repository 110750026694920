export const searchId = (items, id) => {
  for (let i = 0, iLen = items.length; i < iLen; i++) {
    if (items[i].id === id) {
      return items[i]
    }
  }

  return null
}

export const updateId = (origItems, id, field, value) => {
  const items = [...origItems]

  for (let i = 0, iLen = items.length; i < iLen; i++) {
    if (items[i].id === id) {
      if (value instanceof Function) {
        items[i][field] = value(items[i])
      } else {
        items[i][field] = value
      }

      return items
    }
  }

  return items
}
