import React, { useCallback } from 'react'

import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { setTexture } from '../../modules/reducerScene'

import { getLoaded } from '../../modules/reducerLoadImages'

import { typeChangeSelector } from '../../modules/reducer'

import ThreeContainer from './ThreeContainer'
import MeasureWidth from '../Images/MeasureWidth'

import ImageLoader from '../Images/ImageLoader'

const useStyles = (theme) =>
  createUseStyles({
    imageContainer: {
      position: 'relative',
      backgroundColor: ({ theme }) => theme.backgroundImages,
      zIndex: 1,
      height: '100%',
      overflow: 'hidden',
    },
    loadingWrapper: {
      margin: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })

const ImageContainer = () => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const resolveImage = useCallback(
    (image, img) => dispatch(setTexture(image.id, img)),
    [dispatch]
  )

  const typeChange = useSelector(typeChangeSelector)
  const loaded = useSelector(getLoaded)

  const classes = useStyles(theme)({ theme })

  return (
    <div id="image-container-3d" className={classes.imageContainer}>
      <MeasureWidth />

      {typeChange === 'opcion' ? (
        <>
          <ThreeContainer />
          <div className={classes.loadingWrapper}>
            <ImageLoader resolveImage={resolveImage} />
          </div>
        </>
      ) : loaded ? (
        <ThreeContainer />
      ) : (
        <div className={classes.loadingWrapper}>
          <ImageLoader resolveImage={resolveImage} />
        </div>
      )}

      {/*loaded ? (
        <ThreeContainer />
      ) : (
        <div className={classes.loadingWrapper}>
          <ImageLoader resolveImage={resolveImage} />
        </div>
      )*/}
    </div>
  )
}

export default ImageContainer
