import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { useLazyQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

const LOGIN = gql`
  query Login($dominio: String!, $pass: String!) {
    domainAccess(dominio: $dominio, pass: $pass)
  }
`

const Login = ({ setLogged }) => {
  const [password, setPassword] = useState('')

  const [getLogin, { loading, error, data }] = useLazyQuery(LOGIN)

  useEffect(() => {
    if (!loading && data) {
      if (data.domainAccess) {
        setLogged(true)
      } else {
        setPassword('')
      }
    }
  }, [loading, data, setLogged])

  const handleChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = () => {
    getLogin({ variables: { dominio: window.location.host, pass: password } })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <TextField
        id="standard-password-input"
        label="Password"
        type="password"
        value={password}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        onClick={handleSubmit}
        style={{ marginTop: 30 }}
      >
        Login
      </Button>
      {loading ? <div style={{ marginTop: 20 }}>Espere...</div> : null}
      {error ? <div style={{ marginTop: 20 }}>Ocurrió un error</div> : null}
    </div>
  )
}

export default Login
