import React from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import Login from './Login'

const client = new ApolloClient({
  uri: 'https://keystone-claves.herokuapp.com/admin/api'
})

const LoginProvider = ({ setLogged }) => {
  return (
    <ApolloProvider client={client}>
      <Login setLogged={setLogged} />
    </ApolloProvider>
  )
}

export default LoginProvider
