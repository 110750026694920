import { useRef } from 'react'
import { useFrame } from 'react-three-fiber'
import { MathUtils, Vector3 } from 'three'

const ChangeFov = ({ fov, lon, lat }) => {
  const refFov = useRef()
  if (typeof refFov.current === 'undefined' && typeof fov !== 'undefined') {
    refFov.current = fov
  }

  const target = useRef(new Vector3(0, 0, 0))

  useFrame(({ camera }) => {
    const phi = MathUtils.degToRad(90 - lat)
    const theta = MathUtils.degToRad(lon)

    target.current.x = 500 * Math.sin(phi) * Math.cos(theta)
    target.current.y = 500 * Math.cos(phi)
    target.current.z = 500 * Math.sin(phi) * Math.sin(theta)

    camera.lookAt(target.current)

    if (typeof fov !== 'undefined') {
      refFov.current += (fov - refFov.current) * 0.1
      camera.fov = refFov.current
      camera.updateProjectionMatrix()
    }
  })

  return null
}

export default ChangeFov
