import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getEstanciaActualIdHotspots } from '../../modules/reducerScene'
import {
  setStart,
  setEnd,
  setMove,
  setLeave
} from '../../modules/reducerRotate'

import Scene from './Scene'
import Hotspot from './Hotspot'

const ThreeContainer = ({ isFullscreen, history }) => {
  const idHotspots = useSelector(getEstanciaActualIdHotspots)

  const dispatch = useDispatch()

  const onMouseDown = e => {
    dispatch(setStart(e.clientX, e.clientY))
  }
  const onMouseUp = () => {
    dispatch(setEnd())
  }
  const onMouseMove = e => {
    dispatch(setMove(e.clientX, e.clientY))
  }

  const onMouseLeave = e => {
    dispatch(setLeave())
  }

  const onTouchStart = e => {
    dispatch(setStart(e.touches[0].clientX, e.touches[0].clientY))
  }
  const onTouchEnd = () => {
    dispatch(setEnd())
  }
  const onTouchMove = e => {
    dispatch(setMove(e.touches[0].clientX, e.touches[0].clientY))
  }

  return (
    <div
      style={{ height: '100%' }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      <Scene />
      {idHotspots.map(idHotspot => (
        <Hotspot
          key={idHotspot}
          id={idHotspot}
          isFullscreen={isFullscreen}
          history={history}
        />
      ))}
    </div>
  )
}

export default React.memo(ThreeContainer)
