import { createSelector } from 'reselect'
import { CubeTexture, RGBAFormat, LinearFilter } from 'three'
import { searchId } from './utils'

import {
  idEstanciaActualSelector,
  //getImagesVisible,
  //getImagesVisibleAnt,
  getImageVisible,
  getImageVisibleAnt,
  estanciasSelector,
} from './reducer'

import initialStateScene from './initialStateScene'

//Actions
export const SET_TEXTURE = 'SET_TEXTURE'
export const SET_POSITION_HOTSPOT = 'SET_POSITION_HOTSPOT'

export const TOGGLE_AUTOROTATE = 'TOGGLE_AUTOROTATE'

export const RESET_TEXTURES = 'RESET_TEXTURES'

const initialState = {
  ...initialStateScene,
  textures: [],
  points2d: [],
  autoRotate: true,
}

export const setTexture = (id, img) => ({
  type: SET_TEXTURE,
  id,
  img,
})

export const setPositionHotspot = (positions) => ({
  type: SET_POSITION_HOTSPOT,
  positions,
})

export const toggleAutoRotate = () => ({
  type: TOGGLE_AUTOROTATE,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEXTURE:
      if (searchId(state.textures, action.id) === null) {
        return {
          ...state,
          textures: [
            ...state.textures,
            { id: action.id, texture: getTextureFromAtlasFile2(action.img) },
          ],
        }
      } else {
        return state
      }
    case SET_POSITION_HOTSPOT:
      if (action.positions) {
        const points2d = [...state.points2d]

        action.positions.forEach((position) => {
          let esta = false
          for (let i = 0, iLen = points2d.length; i < iLen; i++) {
            if (position.id === points2d[i].id) {
              points2d[i] = position
              esta = true
            }
          }

          if (!esta) {
            points2d.push(position)
          }
        })

        return {
          ...state,
          points2d,
        }
      } else {
        return state
      }
    case TOGGLE_AUTOROTATE:
      return { ...state, autoRotate: !state.autoRotate }
    case RESET_TEXTURES:
      state.textures.forEach((texture) => {
        texture.images.forEach((image) => {
          image.width = 0
          image.height = 0
        })
        texture.dispose()
      })
      return { ...state, textures: [] }
    default:
      return state
  }
}

const texturesSelector = (state) => state.scene.textures
const points2dSelector = (state) => state.scene.points2d
const estanciasSceneSelector = (state) => state.scene.estanciasScene
const hotspots3dSelector = (state) => state.scene.hotspots3d
const pointsSelector = (state) => state.scene.points

export const getEstanciaActualIdHotspots = createSelector(
  [estanciasSceneSelector, idEstanciaActualSelector],
  (estanciasScene, idEstanciaActual) =>
    searchId(estanciasScene, idEstanciaActual).hotspots
)

export const getEstanciaActualHotspots = createSelector(
  [getEstanciaActualIdHotspots, hotspots3dSelector, pointsSelector],
  (idHotspots, hotspots3d, points) =>
    idHotspots.map((idHotspot) => {
      const hotspot = searchId(hotspots3d, idHotspot)
      const hotspotPoints = hotspot.points.map((idPoint) =>
        searchId(points, idPoint)
      )
      return {
        ...hotspot,
        points: hotspotPoints,
      }
    })
)

/*
const getTexturesVisibleParametros = ({ images, textures }) =>
  images.map(image => {
    const textureLoaded = searchId(textures, image.id)
    return {
      ...image,
      texture: textureLoaded === null ? null : textureLoaded.texture
    }
  })

export const getTexturesVisible = createSelector(
  [getImagesVisible, texturesSelector],
  (images, textures) => getTexturesVisibleParametros({ images, textures })
)

export const getAntTexturesVisible = createSelector(
  [getImagesVisibleAnt, texturesSelector],
  (images, textures) => getTexturesVisibleParametros({ images, textures })
)
*/

const getTexturesVisibleParametros = ({ image, textures }) => {
  const textureLoaded = searchId(textures, image.id)

  return {
    ...image,
    texture: textureLoaded === null ? null : textureLoaded.texture,
  }
}

export const getTexturesVisible = createSelector(
  [getImageVisible, texturesSelector],
  (image, textures) => [getTexturesVisibleParametros({ image, textures })]
)

export const getAntTexturesVisible = createSelector(
  [getImageVisibleAnt, texturesSelector],
  (image, textures) => [getTexturesVisibleParametros({ image, textures })]
)

export const getHotspot = () =>
  createSelector(
    [
      hotspots3dSelector,
      points2dSelector,
      estanciasSelector,
      (_, idHotspot) => idHotspot,
    ],
    (hotspots3d, points2d, estancias, idHotspot) => {
      const hotspot3d = searchId(hotspots3d, idHotspot)

      const points = hotspot3d.points.map((idPoint3d) =>
        searchId(points2d, idPoint3d)
      )

      const estancia = searchId(estancias, hotspot3d.estancia)

      return {
        id: hotspot3d.id,
        type: hotspot3d.type,
        points,
        idEstancia: estancia.id,
        tooltip: estancia.title,
      }
    }
  )

export const getIdEstanciasScene = createSelector(
  [estanciasSceneSelector],
  (estanciasScene) => estanciasScene.map((estanciaScene) => estanciaScene.id)
)

export const getHotspotsPlano = createSelector(
  [estanciasSceneSelector, estanciasSelector, idEstanciaActualSelector],
  (estanciasScene, estancias, idEstanciaActual) =>
    estanciasScene.map((estanciaScene) => {
      const estancia = searchId(estancias, estanciaScene.id)

      return {
        id: estanciaScene.id,
        positionPlano: estanciaScene.positionPlano,
        actual: estanciaScene.id === idEstanciaActual,
        tooltip: estancia.title,
      }
    })
)

export const getHotspotPlano = () =>
  createSelector(
    [getHotspotsPlano, (_, idHotspot) => idHotspot],
    (hotspotsPlano, idHotspot) => searchId(hotspotsPlano, idHotspot)
  )

const getTextureFromAtlasFile2 = (imageObj) => {
  const texture = new CubeTexture()
  texture.flipY = false

  let canvas
  let context

  //Invertir
  const canvasImg = document.createElement('canvas')
  context = canvasImg.getContext('2d')
  canvasImg.height = imageObj.height
  canvasImg.width = imageObj.width
  console.log('canvas inicial')
  context.translate(canvasImg.width, 0)
  context.scale(-1, 1)
  context.drawImage(imageObj, 0, 0)
  context.setTransform(1, 0, 0, 1, 0, 0)

  const tileWidth = imageObj.width / 6
  //px nx py ny pz nz
  //px
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 4,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[0] = canvas

  //nx
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 5,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[1] = canvas

  //py
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 3,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[2] = canvas

  //ny
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth * 2,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[3] = canvas

  //pz
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    tileWidth,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[4] = canvas

  //nz
  canvas = document.createElement('canvas')
  context = canvas.getContext('2d')
  canvas.height = tileWidth
  canvas.width = tileWidth
  context.drawImage(
    canvasImg,
    0,
    0,
    tileWidth,
    tileWidth,
    0,
    0,
    tileWidth,
    tileWidth
  )
  texture.images[5] = canvas

  texture.format = RGBAFormat
  texture.minFilter = LinearFilter
  texture.needsUpdate = true
  imageObj.width = 0
  imageObj.height = 0
  console.log('canvas final')
  return texture
}
