import React, { useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import {
  getEndMenu,
  setHeightViewport,
  setPositionY
} from '../../modules/reducerDimensions'

import MeasureHeight from './MeasureHeight'
import MenuSlider from './MenuSlider'
import MenuScroll from './MenuScroll'

//import { setPositionY } from './MenuScroll'

const useStyles = theme =>
  createUseStyles({
    slider: { height: '100%', display: props => props.display },
    menuViewport: {
      flexGrow: '1 !important',
      flexShrink: '1 !important',
      flexBasis: '10px !important',
      overflowY: 'hidden'
    },
    [`@media screen and (max-width: ${theme.mediaScroll})`]: {
      menuViewport: {
        flex: 'none !important',
        overflowY: 'visible'
      }
    }
  })

const MenuViewport = ({ children }) => {
  const endMenu = useSelector(getEndMenu)
  //const showSlider = endMenu < 0
  const showSlider = false
  const dispatch = useDispatch()
  const dsetHeightViewport = useCallback(
    height => dispatch(setHeightViewport(height)),
    [dispatch]
  )

  const theme = useTheme()

  /*const mq = window.matchMedia(`(max-width: ${theme.mediaScroll})`)

  let ocultSlider = false

  if (mq.matches) {
    ocultSlider = true
  }*/

  const ocultSlider = true

  let display = 'block'
  let visibleSlider = true

  if (ocultSlider) {
    display = 'none'
    visibleSlider = false
    dispatch(setPositionY(0))
  } else if (showSlider) {
    display = 'block'
    visibleSlider = true
  } else {
    display = 'none'
    visibleSlider = false
    dispatch(setPositionY(0))
  }

  const classes = useStyles(theme)({ display })

  return (
    <div className={`${classes.menuViewport} col-12 grid-noGutter`}>
      <MeasureHeight setSize={dsetHeightViewport} />

      <MenuScroll endMenu={endMenu} showSlider={showSlider}>
        {children}
      </MenuScroll>

      <div className={`${classes.slider} col-1 sm-hidden`}>
        <MenuSlider visible={visibleSlider} />
      </div>
    </div>
  )
}

export default MenuViewport
