//Actions
export const TOGGLE_OPEN_MENU = 'TOGGLE_OPEN_MENU'
export const TOGGLE_RESUMEN = 'TOGGLE_RESUMEN'
export const OPEN_INFORMATION = 'OPEN_INFORMATION'
export const CLOSE_INFORMATION = 'CLOSE_INFORMATION'

const initialState = {
  openMenu: false,
  openResumen: false,
  srcLogoResumen: 'images/logo_resumen/logo.png',
  openInformation: false
}

export const toggleOpenMenu = () => ({
  type: TOGGLE_OPEN_MENU
})

export const toggleResumen = () => ({
  type: TOGGLE_RESUMEN
})

export const openInformation = () => ({
  type: OPEN_INFORMATION
})

export const closeInformation = () => ({
  type: CLOSE_INFORMATION
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_OPEN_MENU:
      return {
        ...state,
        openMenu: !state.openMenu
      }
    case TOGGLE_RESUMEN:
      return {
        ...state,
        openResumen: !state.openResumen
      }
    case OPEN_INFORMATION:
      return {
        ...state,
        openInformation: true
      }
    case CLOSE_INFORMATION:
      return {
        ...state,
        openInformation: false
      }
    default:
      return state
  }
}
