import React from 'react'

import Measure from 'react-measure'

const MeasureWidth = ({ setSize }) => {
  return (
    <Measure
      bounds
      onResize={contentRect => {
        setSize(contentRect.bounds.width)
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} style={{ width: '100%', height: 0 }} />
      )}
    </Measure>
  )
}

export default MeasureWidth
