import { createSelector } from 'reselect'

//Actions
export const SET_SIZE_IMAGE_CONTAINER_HEIGHT = 'SET_SIZE_IMAGE_CONTAINER_HEIGHT'
export const SET_SIZE_IMAGE_CONTAINER_WIDTH = 'SET_SIZE_IMAGE_CONTAINER_WIDTH'
export const SET_POSITION_Y = 'SET_POSITION_Y'
export const SET_SLIDER_Y = 'SET_SLIDER_Y'
export const SET_MOVING_Y = 'SET_MOVING_Y'
export const SET_HEIGHT_MENU = 'SET_HEIGHT_MENU'
export const SET_HEIGHT_VIEWPORT = 'SET_HEIGHT_VIEWPORT'
export const SET_WIDTH_SLIDER_BUTTONS = 'SET_WIDTH_SLIDER_BUTTONS'
export const SET_WIDTH_BUTTON_ESTANCIA = 'SET_WIDTH_BUTTON_ESTANCIA'
export const SET_POSITION_X = 'SET_POSITION_X'
export const SET_HEIGHT_HEADER = 'SET_HEIGHT_HEADER'

const initialState = {
  sizeImageContainer: {
    width: 0,
    height: 0
  },
  positionY: 0,
  movingY: false,
  sliderY: 0,
  heightMenu: 0,
  heightViewport: 0,
  widthSliderButtons: 0,
  widthButtonEstancia: [0, 0, 0, 0, 0],
  positionX: 0,
  heightHeader: 0
}

export const setSizeImageContainerHeight = height => ({
  type: SET_SIZE_IMAGE_CONTAINER_HEIGHT,
  height
})

export const setSizeImageContainerWidth = width => ({
  type: SET_SIZE_IMAGE_CONTAINER_WIDTH,
  width
})

export const setPositionY = positionY => ({
  type: SET_POSITION_Y,
  positionY
})

export const setMovingY = movingY => ({
  type: SET_MOVING_Y,
  movingY
})

export const setSliderY = sliderY => ({
  type: SET_SLIDER_Y,
  sliderY
})

export const setHeightMenu = height => ({
  type: SET_HEIGHT_MENU,
  height
})

export const setHeightViewport = height => ({
  type: SET_HEIGHT_VIEWPORT,
  height
})

export const setWidthSliderButtons = width => ({
  type: SET_WIDTH_SLIDER_BUTTONS,
  width
})

export const setWidthButtonEstancia = (index, width) => ({
  type: SET_WIDTH_BUTTON_ESTANCIA,
  index,
  width
})

export const setPositionX = positionX => ({
  type: SET_POSITION_X,
  positionX
})

export const setHeightHeader = height => ({
  type: SET_HEIGHT_HEADER,
  height
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIZE_IMAGE_CONTAINER_HEIGHT:
      const size0 = { ...state.sizeImageContainer }
      size0.height = action.height

      return {
        ...state,
        sizeImageContainer: size0
      }
    case SET_SIZE_IMAGE_CONTAINER_WIDTH:
      const size = { ...state.sizeImageContainer }
      size.width = action.width

      return {
        ...state,
        sizeImageContainer: size
      }
    case SET_POSITION_Y:
      return {
        ...state,
        positionY: action.positionY
      }
    case SET_MOVING_Y:
      return {
        ...state,
        movingY: action.movingY
      }
    case SET_SLIDER_Y:
      return {
        ...state,
        sliderY: action.sliderY
      }
    case SET_HEIGHT_MENU:
      return {
        ...state,
        heightMenu: action.height
      }
    case SET_HEIGHT_VIEWPORT:
      return {
        ...state,
        heightViewport: action.height
      }
    case SET_WIDTH_SLIDER_BUTTONS:
      return {
        ...state,
        widthSliderButtons: action.width
      }
    case SET_WIDTH_BUTTON_ESTANCIA:
      const widths = [...state.widthButtonEstancia]
      widths[action.index] = action.width

      return {
        ...state,
        widthButtonEstancia: widths
      }
    case SET_POSITION_X:
      return {
        ...state,
        positionX: action.positionX
      }
    case SET_HEIGHT_HEADER:
      return {
        ...state,
        heightHeader: action.height
      }
    default:
      return state
  }
}

const widthButtonEstanciaSelector = state =>
  state.dimensions.widthButtonEstancia
const widthSliderButtonsSelector = state => state.dimensions.widthSliderButtons

export const getButtonEstanciaX = createSelector(
  [widthButtonEstanciaSelector],
  widthButtonEstancia => {
    let leftX = 0

    return widthButtonEstancia.map(widthEstancia => {
      const antLeftX = leftX
      const newLeftX = leftX + widthEstancia + 40

      leftX = newLeftX

      return { left: antLeftX, right: newLeftX }
    })
  }
)

export const getEndSliderButtons = createSelector(
  [getButtonEstanciaX, widthSliderButtonsSelector],
  (buttonEstanciaX, widthSliderButtons) =>
    widthSliderButtons - buttonEstanciaX[buttonEstanciaX.length - 1].right
)

export const getEndMenu = state => {
  return -(state.dimensions.heightMenu - state.dimensions.heightViewport)
}
