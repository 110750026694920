import React, { useState } from 'react'

import { createUseStyles, ThemeProvider } from 'react-jss'
import Theme from './Theme'

import './gridlex.scss'

import Estancia from './Components/Estancia/Estancia'

import LoginContainer from './Components/Login/LoginContainer'

//import Generador from './Components/Generador/Generador'

const useStyles = createUseStyles({
  app: {
    flexWrap: 'nowrap',
    height: '100vh',
  },
})

const App = () => {
  const [logged, setLogged] = useState(false)

  const classes = useStyles()

  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.app}>
        <Estancia />
        {/*logged ? <Estancia /> : <LoginContainer setLogged={setLogged} />*/}
      </div>
    </ThemeProvider>
  )
}
//<Estancia />
export default App
