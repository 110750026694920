import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { useDispatch } from 'react-redux'
import { incFov, decFov } from '../../modules/reducerFov'
import { goBack } from '../../modules/reducer'

const useStyles = createUseStyles({
  botones: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 5,
    //width: '90%',
    //marginLeft: '5%',
    position: 'absolute',
    zIndex: 100,
    width: 230,
    top: 0
  },
  iconBoton: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    backgroundSize: 'cover',
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent'
    /*  '&:hover': {
      color: ({ theme }) => theme.colorHover
    }*/
  }
})

const Boton = ({ onClick, src, srcHover }) => {
  const [hover, setHover] = useState(false)

  const classes = useStyles()

  return (
    <div
      className={`${classes.iconBoton} `}
      onClick={onClick}
      onMouseEnter={e => setHover(true)}
      onMouseLeave={e => setHover(false)}
    >
      <img
        alt="boton"
        src={hover ? srcHover : src}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

const Botones = () => {
  const dispatch = useDispatch()
  const incFovD = useCallback(e => dispatch(incFov()), [dispatch])
  const decFovD = useCallback(e => dispatch(decFov()), [dispatch])
  const goBackD = useCallback(e => dispatch(goBack()), [dispatch])

  const classes = useStyles()

  return (
    <div className={classes.botones}>
      <Boton
        onClick={goBackD}
        src="images/volver.png"
        srcHover="images/volver__o.png"
      />
      <Boton
        onClick={decFovD}
        src="images/zoom_in.png"
        srcHover="images/zoom_in__o.png"
      />
      <Boton
        onClick={incFovD}
        src="images/zoom_out.png"
        srcHover="images/zoom_out__o.png"
      />
    </div>
  )
}

export default Botones
