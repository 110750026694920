//import { createSelector } from 'reselect'

export const SET_START = 'SET_START'
export const SET_END = 'SET_END'
export const SET_MOVE = 'SET_MOVE'
export const SET_LEAVE = 'SET_LEAVE'
export const SET_LAT_LON = 'SET_LAT_LON'

const initialState = {
  lon: -89.8,
  lat: 0,
  isUserInteracting: false,
  onMouseDownMouseX: 0,
  onMouseDownMouseY: 0,
  onMouseDownLon: 0,
  onMouseDownLat: 0,
  clientX: 0,
  clientY: 0,
  dragging: false
}

export const setStart = (clientX, clientY) => ({
  type: SET_START,
  clientX,
  clientY
})

export const setEnd = () => ({
  type: SET_END
})

export const setLeave = () => ({
  type: SET_LEAVE
})

export const setMove = (clientX, clientY) => ({
  type: SET_MOVE,
  clientX,
  clientY
})

export const setLatLon = (lat, lon) => ({
  type: SET_LAT_LON,
  lat,
  lon
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_START:
      return {
        ...state,
        isUserInteracting: true,
        onMouseDownMouseX: action.clientX,
        onMouseDownMouseY: action.clientY,
        onMouseDownLon: state.lon,
        onMouseDownLat: state.lat,
        clientX: action.clientX,
        clientY: action.clientY,
        dragging: false
      }
    case SET_END:
      return {
        ...state,
        isUserInteracting: false,
        dragging:
          state.dragging ||
          Math.abs(state.clientX - state.onMouseDownMouseX) > 0.5 ||
          Math.abs(state.clientY - state.onMouseDownMouseY) > 0.5
      }
    case SET_LEAVE:
      return {
        ...state,
        isUserInteracting: false,
        dragging: false
      }
    case SET_MOVE:
      return state.isUserInteracting === true
        ? {
            ...state,
            lon:
              (state.onMouseDownMouseX - action.clientX) * 0.1 +
              state.onMouseDownLon,
            lat: Math.max(
              -85,
              Math.min(
                85,
                (action.clientY - state.onMouseDownMouseY) * 0.1 +
                  state.onMouseDownLat
              )
            ),
            clientX: action.clientX,
            clientY: action.clientY,
            dragging:
              state.dragging ||
              Math.abs(state.clientX - state.onMouseDownMouseX) > 0.5 ||
              Math.abs(state.clientY - state.onMouseDownMouseY) > 0.5
          }
        : state
    case SET_LAT_LON:
      return { ...state, lat: action.lat, lon: action.lon }
    default:
      return state
  }
}

/*const initialLatSelector = state => state.rotate.initialLat
const initialLonSelector = state => state.rotate.initialLon

export const getInitialLatLon = createSelector(
  [initialLatSelector,initialLonSelector],
  fovIndex => fovRange[fovIndex]
)*/
