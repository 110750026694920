import React, { memo } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

const useStyles = createUseStyles({
  wrapperTree: {
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    verticalAlign: 'middle',
    fill: 'white',
    padding: '4px 0px 0px 0px'
  },
  cabeceraTree: {
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 15,
    fontWeight: 'normal',
    cursor: 'pointer',
    maxWidth: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: 'rgb(191,191,191)'
  }
})

const Tree = memo(
  ({
    children,
    className = '',
    name,
    style,
    styleIcon,
    padding,
    open = false
  }) => {
    const theme = useTheme()
    const classes = useStyles({ theme })

    return (
      <div className={classes.wrapperTree}>
        <div className={classes.cabeceraTree}>{name}</div>
        <div
          style={{
            padding: 0,
            overflow: 'hidden'
          }}
        >
          {children}
        </div>
      </div>
    )
  }
)

export default Tree
