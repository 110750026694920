import { createSelector } from 'reselect'

export const INC_FOV = 'INV_FOV'
export const DEC_FOV = 'DEC_FOV'
export const SET_DEFAULT_FOV = 'SET_DEFAULT_FOV'
const fovRange = [50, 60, 70, 80, 90, 100, 110]

const initialState = {
  defaultFov: 3,
  fovIndex: 3
}

export const incFov = () => ({
  type: INC_FOV
})

export const decFov = () => ({
  type: DEC_FOV
})

export const setDefaultFov = () => ({
  type: SET_DEFAULT_FOV
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INC_FOV:
      return state.fovIndex === fovRange.length - 1
        ? state
        : { ...state, fovIndex: state.fovIndex + 1 }
    case DEC_FOV:
      return state.fovIndex === 0
        ? state
        : { ...state, fovIndex: state.fovIndex - 1 }
    case SET_DEFAULT_FOV:
      return { ...state, fovIndex: state.defaultFov }
    default:
      return state
  }
}

const fovIndexSelector = state => state.fov.fovIndex

export const getFov = createSelector(
  [fovIndexSelector],
  fovIndex => fovRange[fovIndex]
)
