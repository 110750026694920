import { createSelector } from 'reselect'

import { searchId, updateId } from './utils'
//import { getImagesVisible } from './reducer'
import { getImagesLoader } from './reducer'

//Actions
export const SET_IMAGE_LOADED = 'SET_IMAGE_LOADED'
export const SET_IMAGES_LOADING = 'SET_IMAGES_LOADING'
export const RESET_LOADED = 'RESET_LOADED'

const initialState = {
  imagesLoaded: [],
  imagesLoading: [],
}

export const setImageLoaded = (image) => ({
  type: SET_IMAGE_LOADED,
  image,
})

export const setImagesLoading = (images) => ({
  type: SET_IMAGES_LOADING,
  images,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IMAGE_LOADED:
      let imagesLoaded = [...state.imagesLoaded]

      if (searchId(imagesLoaded, action.image.id) === null) {
        imagesLoaded.push({ id: action.image.id, loaded: true })
      } else {
        imagesLoaded = updateId(imagesLoaded, action.image.id, 'loaded', true)
      }

      const loaded = state.imagesLoading.reduce((result, image) => {
        const imageLoaded = searchId(imagesLoaded, image.id)
        return result && imageLoaded !== null && imageLoaded.loaded
      }, true)

      if (loaded) {
        return {
          ...state,
          imagesLoaded,
          imagesLoading: [],
        }
      } else {
        return {
          ...state,
          imagesLoaded,
        }
      }
    case SET_IMAGES_LOADING:
      const imagesLoading = [...state.imagesLoading]
      const numI = imagesLoading.length

      action.images.forEach((image) => {
        if (
          !imagesLoading.some(
            (im) => im.tipo === image.tipo && im.id === image.id
          )
        ) {
          imagesLoading.push(image)
        }
      })

      if (numI !== imagesLoading.length) {
        return {
          ...state,
          imagesLoading,
        }
      } else {
        return state
      }
    case RESET_LOADED: {
      return {
        imagesLoaded: [],
        imagesLoading: [],
      }
    }
    default:
      return state
  }
}

const imagesLoadingSelector = (state) => state.loadImages.imagesLoading
const imagesLoadedSelector = (state) => state.loadImages.imagesLoaded

export const getPorcentajeLoaded = createSelector(
  [imagesLoadedSelector, imagesLoadingSelector],
  (imagesLoaded, imagesLoading) => {
    const num = imagesLoading.length

    if (num === 0) return 100

    const numLoaded = imagesLoading.reduce((result, image) => {
      const imageLoaded = searchId(imagesLoaded, image.id)

      return imageLoaded === null || !imageLoaded.loaded ? result : result + 1
    }, 0)

    return Math.round((numLoaded * 100) / num)
  }
)

export const getImagesNoLoaded = createSelector(
  [imagesLoadedSelector, getImagesLoader /*getImagesVisible*/],
  (imagesLoaded, images) =>
    images.reduce((result, image) => {
      const imageLoaded = searchId(imagesLoaded, image.id)

      if (imageLoaded === null || !imageLoaded.loaded) {
        result.push({ id: image.id, src: image.src })
      }

      return result
    }, [])
)

export const getLoaded = createSelector(
  [getImagesNoLoaded],
  (imagesNoLoaded) => imagesNoLoaded.length === 0
)
