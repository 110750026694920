import React from 'react'

import Frame from './Frame'

const FrameRow = ({
  idOpcion1,
  idOpcion2,
  idAcabado,
  padding,
  visible1,
  visible2
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <Frame
        key={idOpcion1}
        id={idOpcion1}
        idAcabado={idAcabado}
        padding={0}
        visible={visible1}
      />
      {idOpcion2 !== null ? (
        <Frame
          key={idOpcion2}
          id={idOpcion2}
          idAcabado={idAcabado}
          padding={0}
          visible={visible2}
        />
      ) : null}
    </div>
  )
}

export default FrameRow
