import React, { useMemo, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { getHotspot } from '../../modules/reducerScene'
import { setIdEstanciaActual } from '../../modules/reducer'

import HotspotPoint from './HotspotPoint'
import HotspotArea from './HotspotArea'

const Hotspot = ({ id }) => {
  const getH = useMemo(getHotspot, [])

  const { idEstancia, tooltip, type, points } = useSelector(state =>
    getH(state, id)
  )

  const dispatch = useDispatch()
  const onClick = useCallback(e => dispatch(setIdEstanciaActual(idEstancia)), [
    dispatch,
    idEstancia
  ])

  return type === 'point' ? (
    <HotspotPoint points={points} tooltip={tooltip} onClick={onClick} />
  ) : (
    <HotspotArea points={points} tooltip={tooltip} onClick={onClick} />
  )
}

export default Hotspot
