import React from 'react'
import { createUseStyles } from 'react-jss'

import { useSelector } from 'react-redux'
import { getIdEstanciasScene } from '../../modules/reducerScene'

import useDimensions from './useDimensions'

import HotspotPlano from './HotspotPlano'

const useStyles = createUseStyles({
  planoMenu: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    backgroundImage: 'url("images/360_mesas/plano.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  },
  planoFuera: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    opacity: 0.9,
    textAlign: 'center',
    width: '16%',
    backgroundImage: 'url("images/360_mesas/plano.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    zIndex: 100
  }
})

const Plano = () => {
  const idEstancias = useSelector(state => getIdEstanciasScene(state))

  const [ref, { width }] = useDimensions()

  const classes = useStyles()

  return (
    <div ref={ref} className={classes.planoFuera} style={{ height: width }}>
      {idEstancias.map(idEstancia => (
        <HotspotPlano key={idEstancia} id={idEstancia} />
      ))}
    </div>
  )
}

export default Plano
