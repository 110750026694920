import { combineReducers } from 'redux'
import reducer from './reducer'
import reducerLoadImages from './reducerLoadImages'
import reducerDimensions from './reducerDimensions'
import reducerUI from './reducerUI'
import reducerScene from './reducerScene'
import reducerFov from './reducerFov'
import reducerRotate from './reducerRotate'

export default combineReducers({
  reducer,
  loadImages: reducerLoadImages,
  dimensions: reducerDimensions,
  ui: reducerUI,
  scene: reducerScene,
  fov: reducerFov,
  rotate: reducerRotate
})
