import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { setSizeImageContainerWidth } from '../../modules/reducerDimensions'

import Measure from 'react-measure'

const MeasureWidth = () => {
  const dispatch = useDispatch()

  const dsetSizeImageContainerWidth = useCallback(
    contentRect => {
      dispatch(setSizeImageContainerWidth(contentRect.bounds.width))
    },
    [dispatch]
  )

  return (
    <Measure bounds onResize={dsetSizeImageContainerWidth}>
      {({ measureRef }) => (
        <div ref={measureRef} style={{ width: '100%', height: 0 }} />
      )}
    </Measure>
  )
}

export default MeasureWidth
