import React from 'react'

import ImageContainer from '../Three/ImageContainer'
import MenuEstancias from '../MenuEstancias/MenuEstancias'
import Botones from '../MenuEstancias/Botones'
import Plano from '../Plano/Plano'

const Estancia = () => {
  return (
    <>
      <ImageContainer />
      <MenuEstancias />
      <Botones />
      <Plano />
      <img
        src="images/image_4.png"
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          userSelect: 'none',
          zIndex: 100
        }}
        alt="logo"
      />
    </>
  )
}

export default Estancia
