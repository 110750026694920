import { Vector3 } from 'three'

const initialStateScene = {
  estanciasScene: [
    {
      id: 'e5',
      initialLat: 0,
      initialLon: -89.8,
      hotspots: ['h3'],
      positionPlano: { x: 35, y: 15 }
    },
    {
      id: 'e6',
      initialLat: 0,
      initialLon: -78.5,
      hotspots: ['h10'],
      positionPlano: { x: 50, y: 28 }
    },
    {
      id: 'e7',
      initialLat: 0,
      initialLon: -95.5,
      hotspots: ['h4', 'h5'],
      positionPlano: { x: 65, y: 45 }
    },
    {
      id: 'e8',
      initialLat: 0,
      initialLon: -87.5,
      hotspots: ['h7', 'h8'],
      positionPlano: { x: 20, y: 35 }
    },
    {
      id: 'e9',
      initialLat: 0,
      initialLon: -80,
      hotspots: ['h6', 'h9'],
      positionPlano: { x: 50, y: 42 }
    },
    {
      id: 'e10',
      initialLat: 0,
      initialLon: 134.6,
      hotspots: ['h1', 'h2'],
      positionPlano: { x: 30, y: 80 }
    }
  ],
  hotspots3d: [
    {
      id: 'h1',
      type: 'point',
      points: ['p1'],
      estancia: 'e8'
    },
    {
      id: 'h2',
      type: 'point',
      points: ['p2'],
      estancia: 'e9'
    },
    {
      id: 'h3',
      type: 'point',
      points: ['p4'],
      estancia: 'e8'
    },
    {
      id: 'h4',
      type: 'point',
      points: ['p3'],
      estancia: 'e10'
    },
    {
      id: 'h5',
      type: 'point',
      points: ['p5'],
      estancia: 'e9'
    },
    {
      id: 'h6',
      type: 'point',
      points: ['p6'],
      estancia: 'e7'
    },
    {
      id: 'h7',
      type: 'point',
      points: ['p7'],
      estancia: 'e5'
    },
    {
      id: 'h8',
      type: 'point',
      points: ['p8'],
      estancia: 'e10'
    },
    {
      id: 'h9',
      type: 'point',
      points: ['p9'],
      estancia: 'e10'
    },
    {
      id: 'h10',
      type: 'point',
      points: ['p10'],
      estancia: 'e9'
    }
  ],
  points: [
    {
      id: 'p1',
      position: new Vector3(
        0.12235311109869645,
        -0.012806544165906118,
        1.0927411558570368
      )
    },
    {
      id: 'p2',
      position: new Vector3(
        -1.0236655779844264,
        -0.0445229818902105,
        0.15276362902029267
      )
    },
    {
      id: 'p3',
      position: new Vector3(
        0.5540425206305507,
        -0.042429129242904956,
        -1.0569229671484155
      )
    },
    {
      id: 'p4',
      position: new Vector3(
        0.812404398710464,
        -0.16037088079672548,
        -0.595927032369429
      )
    },
    {
      id: 'p5',
      position: new Vector3(
        1.0065772618661184,
        -0.09946044009411173,
        0.10267560366648022
      )
    },
    {
      id: 'p6',
      position: new Vector3(
        -0.6194143224612383,
        -0.13594639369665465,
        -1.1235950163934123
      )
    },
    {
      id: 'p7',
      position: new Vector3(
        0.23562861310025263,
        -0.038992192037833086,
        0.9885840036276795
      )
    },
    {
      id: 'p8',
      position: new Vector3(
        -0.5688570104949979,
        -0.007793105371145838,
        -1.024739419956403
      )
    },
    {
      id: 'p9',
      position: new Vector3(
        0.351010253732141,
        -0.030306520887789234,
        -0.9524833283706651
      )
    },
    {
      id: 'p10',
      position: new Vector3(
        0.5195698997786898,
        -0.09732948389008662,
        0.8769910566875088
      )
    }
  ]
}

export default initialStateScene
