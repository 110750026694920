import React, { useCallback, useMemo } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { useSelector, useDispatch } from 'react-redux'
import { getHotspotPlano } from '../../modules/reducerScene'
import { setIdEstanciaActual } from '../../modules/reducer'

import ReactTooltip from 'react-tooltip'

const useStyles = createUseStyles({
  hotspot: {
    width: 30,
    height: 30,
    cursor: props => (props.actual ? 'auto' : 'pointer'),
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent'
  },
  hotspotContainer: { position: 'absolute' }
})

const HotspotPlano = ({ id }) => {
  const getH = useMemo(getHotspotPlano, [])

  const { positionPlano, actual, tooltip } = useSelector(state =>
    getH(state, id)
  )

  const theme = useTheme()

  const dispatch = useDispatch()
  const onClick = useCallback(e => dispatch(setIdEstanciaActual(id)), [
    dispatch,
    id
  ])

  const src = actual ? 'images/image_3.png' : 'images/image_2.png'

  const classes = useStyles({ actual, theme })

  return (
    <>
      <ReactTooltip type="light" />
      <div
        className={classes.hotspotContainer}
        data-tip={tooltip}
        style={{
          left: `${positionPlano.x}%`,
          top: `${positionPlano.y}%`
        }}
      >
        <img
          className={classes.hotspot}
          src={src}
          onClick={onClick}
          onTouchEnd={onClick}
          alt=""
        />
      </div>
    </>
  )
}

export default HotspotPlano
