import React, { useMemo, useState, useRef, useEffect } from 'react'
import * as THREE from 'three'

import { a, useSpring } from 'react-spring/three'

import ShaderCube from './ShaderCubeCapas'

function usePrevious(value) {
  const ref = useRef({ textures: [], numChanges: 0 })

  useEffect(() => {
    ref.current = {
      textures: value,
      numChanges: ref.current.numChanges + 1,
    }
  }, [value])

  return {
    antTextures: ref.current.textures,
    numChanges: ref.current.numChanges,
  }
}

const SkyBox = ({ textures, firstCharge, firstTextures }) => {
  const [cube, setCube] = useState(null)

  useMemo(() => {
    const cube1 = new THREE.Mesh(new THREE.BoxBufferGeometry(1500, 1500, 1500))
    cube1.rotateX(Math.PI / 3)
    setCube(cube1)
  }, [])

  const texturesLoaded = textures.reduce(
    (result, texture) => result && texture.texture !== 'null',
    true
  )

  const { antTextures, numChanges } = usePrevious(textures)

  const { progress } = useSpring({
    progress: numChanges % 2 === 0 ? 1 : 0,
  })

  const calculateCapasUniforms = (textures, antTextures, direction) => {
    const result = {}

    const createUniforms = (text, borde, bordeMayuscula) => {
      result[`uniforms-${borde}TextureCube-value`] = text[0].texture //combineTexture(text)
      result[`uniforms-numCapas${bordeMayuscula}-value`] = 0
    }

    let textures1 = textures
    let antTextures1 = antTextures
    let firstTextures1 = firstTextures
    let tempTextures

    if (direction === false) {
      tempTextures = textures1

      if (firstCharge) {
        textures1 = firstTextures1
      } else {
        textures1 = antTextures1
      }

      antTextures1 = tempTextures
      firstTextures1 = tempTextures
    }

    createUniforms(textures1, 'last', 'Last')

    if (firstCharge) {
      if (firstTextures1.length === 0) {
        createUniforms(textures1, 'initial', 'Initial')
      } else {
        createUniforms(firstTextures1, 'initial', 'Initial')
      }
    } else {
      if (antTextures1.length === 0) {
        createUniforms(textures1, 'initial', 'Initial')
      } else {
        createUniforms(antTextures1, 'initial', 'Initial')
      }
    }

    return result
  }

  console.log(
    calculateCapasUniforms(textures, antTextures, numChanges % 2 === 0)
  )

  return texturesLoaded && cube !== null ? (
    <mesh>
      <boxBufferGeometry
        rotation={[Math.PI / 3, 0, 0]}
        attach="geometry"
        args={[1500, 1500, 1500]}
      />
      <a.shaderMaterial
        attach="material"
        args={[
          {
            uniforms: ShaderCube.uniforms,
            vertexShader: ShaderCube.vertexShader,
            fragmentShader: ShaderCube.fragmentShader,
            side: THREE.BackSide,
            depthTest: true,
            depthWrite: false,
            fog: false,
            //transparent: true
          },
        ]}
        uniforms-progress-value={progress.interpolate((i) => {
          return i
        })}
        uniforms-initialPositionCube-value={cube.position}
        uniforms-initialMatrixCube-value={cube.matrixWorld}
        uniforms-lastPositionCube-value={cube.position}
        uniforms-lastMatrixCube-value={cube.matrixWorld}
        {...calculateCapasUniforms(textures, antTextures, numChanges % 2 === 0)}
      />
    </mesh>
  ) : null
}

export default React.memo(SkyBox)
